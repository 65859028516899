<template>
  <div class="product-index">
    <safe-area>
      <div class="product-category">
        <div v-for="(item,i) in list" :key="`category-${i}`" class="product-category-item">
          <div class="product-category-name">{{ lang === 'en'?item.nameEn:item.name }}</div>
          <div class="category-product">
            <template v-for="child in item.product">
              <a :href="`/product/list?id=${child.id}`" class="product-item">
                <img :src="child.thumbnail" class="thumbnail">
                <div class="product-name"> {{ lang === 'en'?child.nameEn:child.name }}</div>
              </a>
            </template>
          </div>
        </div>
      </div>
    </safe-area>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";
import {GetProductGroupByCategoryApi} from "@/api/product";
import {getLang} from "@/utils/lang";

export default {
  name: "index",
  components: {SafeArea},
  data() {
    return {
      lang: getLang(),
      list: [],
      load: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      GetProductGroupByCategoryApi().then(res => {
        this.load = true
        this.list = res
      })
    }
  }
}
</script>

<style scoped>
.product-index {
  background: #f3f4f5;
  padding: 50px 0;
}

.product-category-name {
  padding-left: 10px;
  border-left: 6px solid #ab1018;
  font-weight: bold;
  font-size: 1.3rem;
  color: #333333;
  margin-bottom: 10px;
}

.category-product {
  display: flex;
  flex-wrap: wrap;
}

.product-item {
  margin: 8px;
  flex: 0 0 calc(20% - 16px);
  background: #ffffff;
  border-radius: 2px;
}

.thumbnail {
  display: block;
  margin: 0;
  width: 100%;
  border-radius: 2px;
}

.product-name {
  text-align: center;
  font-size: 0.9rem;
  color: #333333;
  padding: 20px 0 40px;
}

.product-category-item {
  margin-bottom: 20px;
}
</style>
